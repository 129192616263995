import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

class DefaultTheme {
  mainColor: string = "";
  darkColor: string = "";
  lightColor: string = "";
  secondColor: string = "";
  disabledColor: string = "";
  tooltipBg: string = "";
  linkColor: string = "";
  successColor: string = "";
  whiteColor: string = "";
  errorColor: string = "#f41141";
  warningColor: string = "#fc6420";
  greenColor: string = "#63a845";
  fontFamily?: string = "Open Sans";
  buttonFontWeight?: number = 600;

  constructor(colors: any, fontFamily?: string, buttonFontWeight?: number) {
    this.mainColor = colors.mainColor;
    this.darkColor = colors.darkColor;
    this.lightColor = colors.lightColor;
    this.secondColor = colors.secondColor;
    this.disabledColor = colors.disabledColor;
    this.successColor = colors.successColor;
    this.tooltipBg = colors.tooltipBg;
    this.linkColor = colors.linkColor;
    this.whiteColor = colors.whiteColor;
    this.errorColor = colors.errorColor || this.errorColor;
    this.fontFamily = fontFamily || this.fontFamily;
    this.buttonFontWeight = buttonFontWeight || this.buttonFontWeight;
  }

  getTheme() {
    return {
      typography: {
        useNextVariants: true,
        // Use the system font instead of the default Roboto font.
        fontFamily: [this.fontFamily].join(","),
      },
      palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: this.mainColor,
          dark: this.darkColor,
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
          light: this.lightColor,
          main: this.secondColor,
          // dark: will be calculated from palette.secondary.main,
          contrastText: "#ffcc00",
        },
        error: {
          main: this.errorColor,
        },
        // error: will use the default color
      },
      overrides: {
        MuiButton: {
          text: {
            color: "#fff",
            background: this.mainColor,
            height: 45,
            fontSize: 14,
            fontWeight: this.buttonFontWeight,
            textTransform: "uppercase" as "uppercase",
            transition: "all .3s",
            "&$disabled": {
              background: this.disabledColor,
            },
            "&:hover": {
              background: this.mainColor,
              opacity: 0.6,
              transition: "all .3s",
            },
          },
        },
        MuiTooltip: {
          tooltip: {
            color: "#fff",
            padding: "13px 15px",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.3,
            background: this.tooltipBg,
          },
          tooltipPlacementTop: {
            color: "#fff",
            padding: "13px 15px",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.3,
            background: this.tooltipBg,
            maxWidth: 220,
            position: "relative" as "relative",
            "& .arrow": {
              position: "absolute" as "absolute",
              border: "8px solid transparent",
              borderTopColor: this.tooltipBg,
              bottom: -16,
              left: "calc(50% - 8px)",
            },
          },
        },
      },
      customColors: {
        disabledColor: this.disabledColor,
        errorColor: this.errorColor,
        successColor: this.successColor,
        warningColor: this.warningColor,
        greenColor: this.greenColor,
        linkColor: this.linkColor,
        whiteColor: this.whiteColor,
        mainColor: this.mainColor,
      },
    };
  }
}

export default (theme: string | null) => {
  if (theme === "orange") {
    const colors = {
      mainColor: "#fc6420",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#172240",
      linkColor: "#fc6420",
      successColor: "#172241",
    };
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "freshworks") {
    const colors = {
      mainColor: "#bd5081",
      darkColor: "#2D1032",
      lightColor: "#f5f5f5",
      secondColor: "#3377E8",
      disabledColor: "#e4e5e7",
      tooltipBg: "#000000",
      linkColor: "#fc6420",
      successColor: "#172240",
    };
    const defaultTheme = new DefaultTheme(colors, undefined, 500);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "doxy") {
    const colors = {
      mainColor: "#6cc",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#172240",
      linkColor: "#fc6420",
      successColor: "#172240",
    };
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "sunriver") {
    const colors = {
      mainColor: "#000000",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#172240",
      linkColor: "#000000",
      successColor: "#172240",
    };
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "salesloft") {
    const colors = {
      // mainColor: "#1b72b1",
      mainColor: "#0A2417",

      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3574a5",
      linkColor: "#63a845",
      // successColor: "#3fb259",
      successColor: "#054A2E",
      whiteColor: "#f2f2f2",
    };
    const fontFamily = "Hind";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);

    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "ringCentral") {
    const colors = {
      mainColor: "#002fa7",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3574a5",
      linkColor: "#63a845",
      successColor: "#3fb259",
      whiteColor: "#ffffff",
    };
    const fontFamily = "Barlow";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);

    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "sutherland") {
    const colors = {
      mainColor: "#de1b54",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "#312f66",
      disabledColor: "#e4e5e7",
      tooltipBg: "#172240",
      linkColor: "#312f66",
      successColor: "#172240",
    };
    const fontFamily = "Barlow";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);

    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "intermedia") {
    const colors = {
      mainColor: "#0098DB",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "#333333",
      disabledColor: "#e4e5e7",
      tooltipBg: "#172240",
      linkColor: "#312f66",
      successColor: "#172240",
    };
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "nexmo") {
    const colors = {
      mainColor: "#871fff",
      darkColor: "#131415",
      lightColor: "#f5f5f5",
      secondColor: "#131415",
      disabledColor: "#e4e5e7",
      tooltipBg: "#172240",
      linkColor: "#312f66",
      successColor: "#172240",
    };
    const fontFamily = "Spezia";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "lpl") {
    const colors = {
      mainColor: "#005985",
      darkColor: "#212529",
      lightColor: "#f5f5f5",
      secondColor: "#212529",
      disabledColor: "#e4e5e7",
      tooltipBg: "#172240",
      linkColor: "#312f66",
      successColor: "#172240",
    };
    const fontFamily = "univers-cn";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "nice") {
    const colors = {
      mainColor: "#000",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3574a5",
      linkColor: "#63a845",
      successColor: "#000",
    };
    const fontFamily = "Helvetica";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "puzzel") {
    const colors = {
      mainColor: "#ff005d",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3574a5",
      linkColor: "#63a845",
      successColor: "#000",
    };
    const fontFamily = "Helvetica";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "teladochealth") {
    const colors = {
      mainColor: "#351F65",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3574a5",
      linkColor: "#63a845",
      successColor: "#333333",
      whiteColor: "#ffffff",
    };
    const fontFamily = "Montserrat";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);

    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "mend") {
    const colors = {
      mainColor: "#1b6f90",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#282828",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3574a5",
      linkColor: "#63a845",
      successColor: "#282828",
      whiteColor: "#ffffff",
    };
    const fontFamily = "Raleway";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily, 500);

    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "cxengage") {
    const colors = {
      mainColor: "#3E4C59",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#616E7C",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3574a5",
      linkColor: "#63a845",
      successColor: "#282828",
      whiteColor: "#ffffff",
    };
    const fontFamily = "Akkurat";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);

    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "lifesize") {
    const colors = {
      mainColor: "#3E4C59",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#616E7C",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3574a5",
      linkColor: "#63a845",
      successColor: "#282828",
      whiteColor: "#ffffff",
    };
    const fontFamily = "Akkurat";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);

    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "itracks") {
    const colors = {
      mainColor: "#f26722",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "#333333",
      disabledColor: "#e4e5e7",
      tooltipBg: "#333333",
      linkColor: "#fc6420",
      successColor: "#333333",
    };
    const fontFamily = "PT Sans";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "navis") {
    const colors = {
      mainColor: "#3f4963",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#3f4963",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3f4963",
      linkColor: "#3f4963",
      successColor: "#172241",
    };
    const fontFamily = "Source Sans Pro";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily, 300);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "digit") {
    const colors = {
      mainColor: "rgba(9,11,13,.55)",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "rgba(9,11,13,.55)",
      disabledColor: "#e4e5e7",
      tooltipBg: "rgba(9,11,13,.55)",
      linkColor: "#fc6420",
      successColor: "rgba(9,11,13,.55)",
    };
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "extcare-webex") {
    const colors = {
      mainColor: "#26241c",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "#26241c",
      disabledColor: "#e4e5e7",
      tooltipBg: "#26241c",
      linkColor: "#fc6420",
      successColor: "#26241c",
    };
    const fontFamily = "Public Sans";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "amazon") {
    const colors = {
      mainColor: "#007991",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#172240",
      linkColor: "#fc6420",
      successColor: "#008a00",
      warningColor: "#dddd00",
      errorColor: "#dd0000",
    };
    const fontFamily = "Amazon Ember";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily, 400);
    // const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "engauge") {
    const colors = {
      mainColor: "#020202",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "#333333",
      disabledColor: "#e4e5e7",
      tooltipBg: "#333333",
      linkColor: "#fc6420",
      successColor: "rgba(69,173,31,0.91)",
      warningColor: "#dddd00",
      errorColor: "#dd0000",
    };
    const fontFamily = "Droid Sans";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "avatour") {
    const colors = {
      mainColor: "#223D4F",
      darkColor: "#151515",
      lightColor: "#fff",
      secondColor: "#223D4F",
      disabledColor: "#e4e5e7",
      tooltipBg: "#0d1922",
      linkColor: "#fc6420",
      successColor: "#fff",
      warningColor: "#dddd00",
      errorColor: "#dd0000",
    };
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "zaurus") {
    const colors = {
      mainColor: "#21aafc",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "#333",
      disabledColor: "#e4e5e7",
      tooltipBg: "#333333",
      linkColor: "#fc6420",
      warningColor: "#dddd00",
      errorColor: "#dd0000",
    };
    const defaultTheme = new DefaultTheme(colors);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "togetherseniorhealth") {
    const colors = {
      mainColor: "rgb(70, 8, 116)",
      darkColor: "#151515",
      lightColor: "#f5f5f5",
      secondColor: "rgb(94, 94, 94)",
      disabledColor: "#e4e5e7",
      tooltipBg: "rgb(94, 94, 94)",
      linkColor: "#fc6420",
      successColor: "rgb(94, 94, 94)",
    };
    const fontFamily = "Lato";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "availconsole") {
    const colors = {
      mainColor: "#478ec7",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "#3574a5",
      linkColor: "#63a845",
      successColor: "rgb(28, 110, 174)",
      warningColor: "rgb(207,122,48)",
      errorColor: "rgb(150, 41, 41)",
    };
    const fontFamily = "Lato";
    document.body.style.fontFamily = fontFamily;
    const defaultTheme = new DefaultTheme(colors, fontFamily);
    return createMuiTheme(defaultTheme.getTheme());
  } else if (theme === "dialpad") {
    const colors = {
      mainColor: "rgb(124, 82, 255)",
      darkColor: "#333333",
      lightColor: "#f5f5f5",
      secondColor: "#172240",
      disabledColor: "#e4e5e7",
      tooltipBg: "rgb(124, 82, 255)",
      linkColor: "#63a845",
      successColor: "rgb(80, 203, 42)",
      warningColor: "rgb(207,122,48)",
      errorColor: "rgb(150, 41, 41)",
    };
    const fontFamily = "ArchivoSemiExpanded";
    document.body.style.fontFamily = fontFamily;
    document.body.style.fontWeight = "500";
    document.body.style.fontSize = "15px";
    const defaultTheme = new DefaultTheme(colors, fontFamily);
    return createMuiTheme(defaultTheme.getTheme());
  }
  const colors = {
    mainColor: "#478ec7",
    darkColor: "#333333",
    lightColor: "#f5f5f5",
    secondColor: "#172240",
    disabledColor: "#e4e5e7",
    tooltipBg: "#3574a5",
    linkColor: "#63a845",
    successColor: "#50cb2a",
  };
  const defaultTheme = new DefaultTheme(colors);
  return createMuiTheme(defaultTheme.getTheme());
};
